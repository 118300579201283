<template>
    <div class="graph-totals-holder">
        <div class="graph-totals-line"> Selectie totaal: {{ totals['current'] }}</div>
        <div class="graph-totals-line"> 1 jaar eerder: {{ totals['previous'] }}</div>
        <div class="graph-totals-line"> 2 jaar eerder: {{ totals['superPrevious'] }}</div>
    </div>

    <div class="graph">
        <div class="price-graph" :style="`width: ${(preparedData.count()*96)+90}px`">
            <div class="column" @click="$emit('customerClicked', customer)" v-for="customer of preparedData">
                <div class="bar-container">
                    <div
                        v-for="period of ['current', 'previous', 'superPrevious']"
                        class="point"
                        :class="period === 'superPrevious' ? 'super-previous' : period"
                        :style="`bottom: ${getBottom(customer, period)}`"
                    >
                        <div class="point-value">
                            <span class="bar-euro-sign">€</span>{{ formatNumber(customer[period]) }}
                        </div>
                    </div>
                </div>
                <div class="label-holder">
                    <div class="label" v-html="customer.name"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import collect from 'collect.js'

    const emit = defineEmits(['customerClicked'])
    const props = defineProps(['preparedData', 'formatNumber'])

    const getBottom = function(customer, column) {
        if (! customer[column]) {
            return '0px';
        }

        if (customer[column] > 0.96) {
            return 'calc(100% - 16px)';
        }

        if (customer[column] < 0) {
            return '0px'
        }

        return customer[column] * 100 + '%';
    }

    const totals = computed(() => (
        {
            current: `€ ${Math.round(collect(props.preparedData).pluck('current').filter().avg()*100)/100}`,
            previous: `€ ${Math.round(collect(props.preparedData).pluck('previous').filter().avg()*100)/100}`,
            superPrevious: `€ ${Math.round(collect(props.preparedData).pluck('superPrevious').filter().avg()*100)/100}`
        }
    ));
</script>
